import {
  IconDiscord,
  IconMedium,
  IconTelegram,
  IconTwitter,
} from "../../components";

export const navLinkData = [
  {
    label: "Home",
    to: "#home",
  },
  {
    label: "About",
    to: "#about",
  },
  {
    label: "Tokenomics",
    to: "#tokenomics",
  },
  {
    label: "Roadmap",
    to: "#roadmap",
  },
  {
    label: "Whitepaper",
    to: "#whitepaper",
  },
];

export const socialLinks = [
  {
    icon: <IconTelegram />,
    link: "https://telegram.org",
  },
  {
    icon: <IconDiscord />,
    link: "https://discord.com",
  },
  {
    icon: <IconTwitter />,
    link: "https://twitter.com",
  },
  {
    icon: <IconMedium />,
    link: "https://medium.com",
  },
];
