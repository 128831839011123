export const favoriteTabNameList = [
  {
    image: "/images/userdashboard/favoritelist/game1.png",
    name: "Pirates Land",
    network: "Solana",
    playernum: 800,
  },
  {
    image: "/images/userdashboard/favoritelist/game2.png",
    name: "Meta Moba",
    network: "Solana",
    playernum: 800,
  },
  {
    image: "/images/userdashboard/favoritelist/game3.png",
    name: "War Hands NFT",
    network: "Solana",
    playernum: 800,
  },
  {
    image: "/images/userdashboard/favoritelist/game4.png",
    name: "Twilight",
    network: "Solana",
    playernum: 800,
  },
];
