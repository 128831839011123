export const favoriteTabNameList = [
  {
    key: "empty",
    label: "EMPTY",
  },
  {
    key: "game",
    label: "GAME",
  },
  {
    key: "nfts",
    label: "NFTS",
  },
  {
    key: "merch",
    label: "MERCHENDISE",
  },
  {
    key: "streamed",
    label: "STREAMED VIDEOS",
  },
  {
    key: "events",
    label: "EVENTS",
  },
];
