export const GamerListInfo = [
  {
    avatar: "/images/userdashboard/useravatar/user1.png",
    name: "Kelvin11",
    level: "Pro Gamer",
  },
  {
    avatar: "/images/userdashboard/useravatar/user2.png",
    name: "Alex Bay",
    level: "Novice",
  },
  {
    avatar: "/images/userdashboard/useravatar/user3.png",
    name: "Kendrick Perkins",
    level: "Amateur",
  },
  {
    avatar: "/images/userdashboard/useravatar/user4.png",
    name: "Kelvin11",
    level: "Pro Gamer",
  },
];
